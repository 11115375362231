<template>
  <div class="main-content">
    <div class="main-content__inner welcome-frame">
      <img class="info-frame__graphics" src="@/assets/confirmation.svg" />
      <div class="info-frame__greeting text-32-800">
        {{ $t('greeting')}}
      </div>
      <div class="info-frame__text">
        {{ $t('afterWelcome')}}
      </div>
      <simple-button
        class="info-frame__action-button"
        icon="plus"
        value="Add your first product"
        @click="() => { $router.push('/search-product') }"
      />
    </div>
  </div>
</template>

<script>
import api from '@/api/api'

export default {
  name: 'Welcome',
  mounted () {
    api.seenWelcomePage()
  },
}
</script>

<style lang="scss" scoped>
.main-content__page-title {
  height: 60px;
  line-height: 60px;
  font: {
    size: 16px;
    weight: 400;
  }
  margin: 0;
}

.main-content__inner {
  border: none;
  text-align: center;
}
</style>
